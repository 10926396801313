@keyframes shining {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.sunMoonWrap {
  --basis-size: 100px;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;

  .circle {
    position: fixed;
    top: 10%;
    left: 50%;
    z-index: 9999999;
    width: 50%;
    height: 80%;
    transition: all 0.6s ease;
    translate: -50%;

    &.hide {
      rotate: 180deg;

      .sun {
        opacity: 0;
      }
    }

    &.show {
      rotate: 0deg;

      .moon {
        opacity: 0;
      }
    }
  }

  .sun {
    position: fixed;
    top: 0;
    left: 50%;
    width: 100px;
    height: 100px;
    box-shadow: 0 0 35px 5px yellow, 0 0 25px 10px yellow inset;
    background-color: orange;
    border-radius: 50%;
    transition: opacity 0.3s;
    translate: -50%;
  }

  .moon {
    $moonColor: #ccc; //rgb(209, 70, 22)
    $darkMoonColor: darken($moonColor, 5%);
    $darkerMoonColor: darken($moonColor, 10%);
    $darkestMoonColor: darken($moonColor, 15%);

    position: fixed;
    bottom: 0;
    left: 50%;
    display: block;
    width: var(--basis-size);
    height: var(--basis-size);
    //width: 100px;
    //height: 100px;
    box-shadow: 0 0 35px 3px white;
    background-color: #ccc;
    background-image: radial-gradient(
        circle at 0 20%,
        $moonColor 0%,
        transparent 65%,
        $darkMoonColor 65%
    );
    border-radius: 100%;
    //box-shadow: 10px 0 0 0 rgba(#000000, 0.2) inset, 0 0 35px 5px white;
    //background-color: #7B7B7B;
    //border-radius: 9999px;
    translate: -50%;
    transition: opacity 0.3s;


    //&::before,
    //&::after {
    //  position: absolute;
    //  display: block;
    //  background-color: #FFFFFF;
    //  border-radius: 9999px;
    //  content: '';
    //}
    //
    //&::before {
    //  top: 18px;
    //  left: 15px;
    //  width: 12px;
    //  height: 12px;
    //  opacity: 0.6;
    //}
    //
    //&::after {
    //  right: 22px;
    //  bottom: 26px;
    //  width: 20px;
    //  height: 20px;
    //  opacity: 0.8;
    //}

    .spot {
      position: absolute;
      background-color: $darkMoonColor;
      background-image: radial-gradient(
          circle at 100% 80%,
          $darkerMoonColor 0%,
          $darkerMoonColor 65%,
          $darkestMoonColor 65%
      );
      border-radius: 100%;

      &--1 {
        top: 15%;
        left: 40%;
        width: calc(var(--basis-size) / 8);
        height: calc(var(--basis-size) / 8);
      }

      &--2 {
        top: 63%;
        left: 48%;
        width: calc(var(--basis-size) / 4);
        height: calc(var(--basis-size) / 4);
      }

      &--3 {
        top: 28%;
        left: 14%;
        width: calc(var(--basis-size) / 4);
        height: calc(var(--basis-size) / 4);
      }

      &--4 {
        top: 64%;
        left: 15%;
        width: calc(var(--basis-size) / 6);
        height: calc(var(--basis-size) / 6);
      }

      &--5 {
        top: 38%;
        left: 73%;
        width: calc(var(--basis-size) / 7);
        height: calc(var(--basis-size) / 7);
      }

      &--6 {
        top: 17%;
        left: 20%;
        width: calc(var(--basis-size) / 14);
        height: calc(var(--basis-size) / 14);
      }

      &--7 {
        top: 63%;
        left: 35%;
        width: calc(var(--basis-size) / 16);
        height: calc(var(--basis-size) / 16);
      }

      &--8 {
        top: 48%;
        left: 56%;
        width: calc(var(--basis-size) / 16);
        height: calc(var(--basis-size) / 16);
      }
    }
  }

  .starSkye {
    position: fixed;
    width: 100%;
    height: 80%;
    opacity: 0;
    transition: all 0.3s ease;

    &.show {
      opacity: 1;
    }
  }

  .star {
    position: absolute;
    width: random(2) * 1px;
    height: random(3) * 1px;
    background-color: #fff;
    border-radius: 50%;
  }

  @for $i from 0 through 150 {
    .star#{$i} {
      top: random() * 100%;
      left: random() * 100%;
      animation: random(5) * 1s shining (calc(random(50) / 10)) * 1s infinite;
    }
  }
}

@media only screen and (max-height: 500px) and (max-width: 940px) and (orientation: landscape) {
  .sunMoonWrap {
    --basis-size: 70px;

    .sun {
      width: 70px;
      height: 70px;
    }
  }
}


//<div class="moon">
//<div></div>
//<div></div>
//<div></div>
//</div>

//.moon {
//  position: absolute;
//  margin-top: 20px;
//  background: #d9d8d0;
//  box-shadow: inset -10px 2px 0 0px #899098;
//  width: 22.5%;
//  height: 22.5%;
//  border-radius: 100%;
//  position: relative;
//  left: 120%;
//  margin-left: -50px;
//  top: -50%;
//  overflow: hidden;
//
//  -webkit-transition: all 1.1s 0s ease;
//  transition: all 1.1s 0s ease;
//}
//.night .moon {
//  left: 65%;
//  top: -10%;
//
//  -webkit-transition: all 1.1s 0.4s ease;
//  transition: all 1.1s 0.4s ease;
//}
//.moon div:nth-child(n) {
//  position: relative;
//  background: #b9b8b0;
//  border-radius: 50%;
//  box-shadow: inset 4px -2px 0 0px #535457;
//}
//.moon div:nth-child(1) {
//  top: 25%;
//  left: 12%;
//  width: 27%;
//  height: 27%;
//}
//.moon div:nth-child(2) {
//  top: -11%;
//  left: 60%;
//  width: 16%;
//  height: 16%;
//}
//.moon div:nth-child(3) {
//  top: 25%;
//  left: 44%;
//  width: 16%;
//  height: 16%;
//}
