html, body, #root, main {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

* {
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: white;
  }
}

.skeletonLoader {
  position: relative;
  overflow: hidden;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    //background: linear-gradient( 90deg, transparent, rgba(0, 0, 0, 0.08), transparent );
    background: linear-gradient(92deg, transparent, rgba(255, 255, 255, 0.07) 40%, rgba(255, 255, 255, 0.08) 50%, rgba(255, 255, 255, 0.07) 60%, transparent);
    content: '';
    animation: core-skeleton-animation 1.1s linear 0s infinite;
  }

  &.loaded {
    &:before {
      display: none;
    }
  }

  @keyframes core-skeleton-animation {
    0% {
      transform: translateX(-100%);
    }
    50% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(100%);
    }
  }

}

@media screen and (max-height: 500px), (max-width: 600px) and (orientation: portrait) {
  html, body, #root, main {
    height: 100dvh;
  }
}
