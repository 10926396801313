@keyframes moveCloudsToRight {
  0% {
    margin-left: randomNum(102, 150) + vw;
  }
  100% {
    margin-left: -102vw;
  }
}

@keyframes moveCloudsToLeft {
  0% {
    margin-left: -(randomNum(5, 50)) + vw;
  }
  100% {
    margin-left: 102vw;
  }
}

@function randomNum($min, $max) {
  $rand: random();
  $randomNum: $min + floor($rand * (($max - $min) + 1));

  @return $randomNum;
}

@function randomFloatInRange($min, $max) {
  @return $min + random() * ($max - $min);
}

.clouds {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .cloud {
    width: 60px;
    height: 60px;
    box-shadow: #FFFFFF 65px -15px 0 -5px,
    #FFFFFF 25px -25px,
    #FFFFFF 30px 10px,
    #FFFFFF 60px 15px 0 -10px,
    #FFFFFF 85px 5px 0 -5px;
    background: #FFFFFF;
    border-radius: 50%;

    &:after {
      position: absolute;
      top: 65px;
      left: 2px;
      width: 2px;
      height: 6px;
      box-sizing: border-box;
      color: #FFF;
      animation: cloudLoader 0.6s linear infinite;
      content: '';
    }
  }

  .rightMove {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 15px;

    @for $i from 1 through 10 {
      .cloud:nth-of-type(#{$i}) {
        margin-left: randomNum(102, 150) + vw;
        opacity: randomFloatInRange(0.1, 0.5);
        animation-delay: randomNum(500, 7000) + ms;
        animation-duration: randomNum(75, 120) + s;
        animation-iteration-count: infinite;
        animation-name: moveCloudsToRight;
        animation-timing-function: linear;
        animation-direction: alternate;
        scale: randomFloatInRange(0.3, 0.7);
      }
    }
  }

  .leftMove {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 15px;

    @for $i from 1 through 10 {
      .cloud:nth-of-type(#{$i}) {
        margin-left: -(randomNum(5, 50)) + vw;
        opacity: randomFloatInRange(0.1, 0.5);
        //animation-delay: randomNum(500, 4000) + ms;
        animation-duration: randomNum(75, 120) + s;
        animation-iteration-count: infinite;
        animation-name: moveCloudsToLeft;
        animation-timing-function: linear;
        animation-direction: alternate;
        scale: randomFloatInRange(0.3, 0.7);
      }
    }
  }
}

@media screen and (min-width: 2000px) {
  .clouds {
    .rightMove {
      @for $i from 1 through 10 {
        .cloud:nth-of-type(#{$i}) {
          animation-duration: randomNum(60, 200) + s;
        }
      }
    }

    .leftMove {
      @for $i from 1 through 10 {
        .cloud:nth-of-type(#{$i}) {
          animation-duration: randomNum(60, 200) + s;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .clouds {
    .rightMove {
      @for $i from 1 through 10 {
        .cloud:nth-of-type(#{$i}) {
          margin-left: randomNum(102, 150) + vw;
          opacity: randomFloatInRange(0.1, 0.5);
          animation-delay: randomNum(500, 7000) + ms;
          animation-duration: randomNum(25, 50) + s;
          animation-iteration-count: infinite;
          animation-name: moveCloudsToRight;
          animation-timing-function: linear;
          animation-direction: alternate;
          scale: randomFloatInRange(0.3, 0.5);
        }
      }
    }

    .leftMove {
      @for $i from 1 through 10 {
        .cloud:nth-of-type(#{$i}) {
          margin-left: -(randomNum(5, 50)) + vw;
          opacity: randomFloatInRange(0.1, 0.5);
          animation-delay: randomNum(500, 4000) + ms;
          animation-duration: randomNum(25, 50) + s;
          animation-iteration-count: infinite;
          animation-name: moveCloudsToLeft;
          animation-timing-function: linear;
          animation-direction: alternate;
          scale: randomFloatInRange(0.3, 0.5);
        }
      }
    }
  }
}
