@keyframes animateRocket {
  to {
    transform: translateX(-50%) translateY(3px);
  }
}

@keyframes rocket {
  0% {
    scale: 0.8;
    top: 60%;
    left: 15%;
  }
  50% {
    rotate: 30deg;
  }
  80% {
    scale: 0.15;
    top: 6.5%;
    left: 51%;
    rotate: 13deg;
  }
  100% {
    scale: 0.15;
    top: 8.6%;
    left: 50.5%;
    rotate: 15deg;
  }
}

@keyframes rocketMobile {
  0% {
    scale: 0.5;
    top: 55%;
    left: 100%;
  }
  30% {
    rotate: -22deg;
  }
  60% {
    rotate: -22deg;
    right: 75%;
  }
  80% {
    scale: 0.15;
    top: 6.5%;
    left: 52%;
    rotate: -13deg;
  }
  100% {
    scale: 0.15;
    top: 8.2%;
    left: 52%;
    rotate: 13deg;
  }
}

@keyframes animFire {
  0% {
    border-top: 50px solid #F2993B;
  }
  25% {
    border-top: 50px solid #F2993B;
  }
  50% {
    border-top: 55px solid #F2993B;
  }
  65% {
    border-top: 48px solid #F2993B;
  }
  80% {
    border-top: 40px solid #F2993B;
  }
  100% {
    border-top: 60px solid #F2993B;
  }
}

@keyframes flame {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes flameMobile {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes flag {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flagMobile {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.rocketWrap {
  position: fixed;
  z-index: 0;
  transform-origin: top left;
  animation: rocket 5s;
  scale: 0.15;
  left: 50.5%;
  rotate: 15deg;
  top: 8.6%;

  .rocket {
    position: relative;
    z-index: 2;
    width: 80px;
    height: 180px;
    box-shadow: 0 -3px 0 #FFF;
    background-color: #dadada;
    border-radius: 50% 50% 25% 25% / 50% 50% 40% 40%;
    transform-style: preserve-3d;
    //animation: animateRocket 1s linear alternate infinite;

    &::before {
      position: absolute;
      bottom: -10px;
      left: 50%;
      width: 55%;
      height: 10px;
      background-color: #d43e07;
      border-radius: 0 0 25% 25% / 50% 50% 40% 40%;
      transform: translateX(-50%);
      content: '';
    }

    &:after {
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: -10;
      width: 155%;
      height: 55px;
      background-color: #9e554f;
      border-radius: 50% 50% 10% 10% / 90% 90% 20% 20%;
      transform: translateX(-50%) translateZ(-1px);
      content: '';
    }

    .rocketWindow {
      position: absolute;
      top: 40px;
      left: 50%;
      width: 30px;
      height: 30px;
      box-sizing: initial;
      background-color: #9e554f;
      border: 5px solid #adadad;
      border-radius: 50%;
      transform: translateX(-50%);
    }
  }

  .rocketFlame {
    position: absolute;
    bottom: -9px;
    left: 19px;
    z-index: 0;
    opacity: 0;
    animation: flame 5.3s;

    &:after {
      position: absolute;
      border-top: 60px solid #F2993B;
      border-right: 20px solid transparent;
      border-left: 20px solid transparent;
      animation: animFire .5s infinite;
      content: '';
    }
  }

  .flag {
    position: absolute;
    right: -120px;
    top: 90px;
    opacity: 1;
    animation: flag 5.1s;

    &:before {
      content: '';
      display: block;
      width: 2px;
      height: 100px;
      background: black;
      position: absolute;
    }

    .colors {
      width: 80px;
      height: 20px;
      background: #0033A0;
      padding: 20px 0;

      &:before {
        display: block;
        width: 100%;
        height: 20px;
        background: #f00;
        content: '';
        position: relative;
        top: -20px;
      }

      &:after {
        display: block;
        width: 100%;
        height: 20px;
        background: #F2A800;
        content: '';
      }
    }
  }

  &.hide {
    animation: none;

    .rocketFlame, .flag {
      animation: none;
    }
  }
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .rocketWrap {
    left: 52%;
    rotate: 13deg;
    top: 8.2%;
    animation: rocketMobile 4s;

    .rocketFlame {
      animation: flameMobile 4s;
    }

    .flag {
      opacity: 1;
      animation: flagMobile 4s;
    }
  }
}
