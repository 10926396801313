/*
Resset sass
--------------------------------------------
*/

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  vertical-align: baseline;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0 !important;
  font: inherit;
}

* {
  -webkit-text-size-adjust: auto !important;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

a {
  border: 0;
}

html {
  overflow-x: hidden;
}

body {
  line-height: 1;
  word-wrap: break-word;
}

ol,
ul {
  padding: 0;
  margin: 0;
  background-image: none;
  list-style: none inside none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Remove controls from Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; /* Removes leftover margin */
}

input,
textarea {
  -webkit-appearance: none;
  appearance: none;
  line-height: normal;
  -moz-appearance: none;
}

:focus {
  outline-style: none;
  outline-color: transparent;
}

button::-moz-focus-inner {
  border: 0;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.cgs-clear {
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  clear: both !important;
}

button {
  outline: none !important;
}

a, label, button {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
  box-shadow: 0 0 0 30px white inset;
}

