@keyframes flap {
  0% {
    transform: skew(10deg) rotateX(50deg);
  }
  100% {
    transform: skew(15deg) rotateX(120deg);
  }
}

@keyframes flyFront {
  20% {
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-120vw, -0, 0) rotate(0deg);
  }
}

@keyframes flyBack {
  0% {
    transform: translate3d(0, 0, 0) scale(.6) scaleX(-1) rotate(-15deg);
  }
  80% {
    transform: translate3d(120vw, -150px, 0) scale(.6) scaleX(-1) rotate(15deg);
  }
  100% {
    transform: translate3d(120vw, -150px, 0) scale(.6) scaleX(-1) rotate(15deg);
  }
}

.birdsContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  overflow: hidden;
  z-index: 4;
  top: 0;
  left: 0;

  .birds {
    position: absolute;
    z-index: 9999;
    width: 100px;
    height: 100px;

    &.front {
      animation: flyFront 30s linear;
      animation-iteration-count: infinite;
      animation-delay: 13s;
      bottom: 200px;
      right: -130px;
      opacity: 0.7;
    }

    &.back {
      animation: flyBack 30s linear;
      //animation-direction: forwards;
      animation-iteration-count: infinite;
      animation-delay: 7s;
      top: 20%;
      left: -130px;
    }

    .bird {
      position: absolute;
      transform: scale(0.15);
    }

    $birdTop: -14px, -20px, auto, 15px, 30px, 38px, -5px, 10px, 25px, 40px, -10px, 5px;
    $birdLeft: 28px, -20px, 10px, 20px, -5px, -25px, -35px, -25px, -50px, -40px, -75px, -65px;

    @for $i from 1 through 12 {
      $delay: random(2) - .5 + s;

      .bird:nth-of-type(#{$i}) {
        top: nth($birdTop, $i);
        left: nth($birdLeft, $i);

        .wing1 {
          animation: flap .6s ease-in-out;
          animation-iteration-count: infinite;
          animation-direction: alternate;
          transform-origin: 0 0;
          //animation-duration: $delay;
          //animation-timing-function: ease;
          animation-delay: $delay;
        }
      }
    }
  }

  .birdBody {
    clip-path: polygon(0 100%, 20% 20%, 40% 0, 100% 100%, 20% 80%);
    background: #000;
    width: 150px;
    height: 40px;
  }

  .wing1 {
    position: relative;
    left: 40px;
    top: -20px;
    width: 40px;
    height: 50px;
    background: #000;
    transform: skew(10deg);

    .wing2 {
      position: absolute;
      bottom: -25px;
      left: 13px;

      transform: rotate(-5deg);
    }

    .wing3 {
      width: 40px;
      height: 30px;
      background: #000;
      transform: skew(40deg);
    }
  }
}

@media screen and (min-width: 2000px) {
  .birdsContainer {
    .birds {
      &.front {
        animation: flyFront 25s linear;
        animation-iteration-count: infinite;
        animation-delay: 13s;
      }

      &.back {
        animation: flyBack 35s linear;
        //animation-direction: forwards;
        animation-iteration-count: infinite;
        animation-delay: 7s;
      }
    }
  }
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  @keyframes flyBack {
    0% {
      transform: translate3d(0, 0, 0) scale(.6) scaleX(-1) rotate(-15deg);
    }
    80% {
      transform: translate3d(130vw, -150px, 0) scale(.6) scaleX(-1) rotate(15deg);
    }
    100% {
      transform: translate3d(130vw, -150px, 0) scale(.6) scaleX(-1) rotate(15deg);
    }
  }

  @keyframes flyFront {
    20% {
      transform: translate3d(0, 0, 0) rotate(0deg);
    }
    100% {
      transform: translate3d(-130vw, -0, 0) rotate(0deg);
    }
  }

  .birdsContainer {
    .birds {
      &.front {
        animation: flyFront 13s linear;
        animation-delay: 13s;
      }

      &.back {
        animation: flyBack 13s linear;
        animation-delay: 7s;
      }
    }
  }
}

