@keyframes ufoLight {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*@keyframes ufo {
  0% {
    top: -100px;
    left: 100%;
  }
  40% {
    top: 50%;
    left: -20%;
    rotate: 15deg;
    scale: 0.3;
  }
  41% {
    top: 50%;
    left: -20%;
    translate: -50%;
    rotate: 0deg;
    scale: 0.3;
  }
  60% {
    top: 60%;
    right: unset;
    left: 50%;
    translate: -50%;
    rotate: 0deg;
    scale: 1;
  }
  86% {
    top: 60%;
    right: unset;
    left: 50%;
    translate: -50%;
    rotate: 0deg;
    scale: 1;
  }
  100% {
    top: -100px;
    left: 100%;
    rotate: -15deg;
  }
}*/

@keyframes ufo {
  0% {
    top: -100px;
  }
  60% {
    top: 50%;
    scale: 1;
  }
  86% {
    top: 52%;
    scale: 1;
  }
  100% {
    top: -100px;
  }
}

@keyframes ufoMobile {
  0% {
    top: -100px;
  }
  60% {
    top: 50%;
    scale: 0.4;
  }
  86% {
    top: 50%;
    scale: 0.4;
  }
  100% {
    top: -100px;
  }
}

@keyframes light {
  0% {
    opacity: 0;
  }
  62% {
    width: 0;
    opacity: 0;
  }
  64% {
    width: 200px;
    opacity: 1;
  }
  83% {
    width: 200px;
    opacity: 1;
  }
  85% {
    width: 0;
    opacity: 0;
  }
}

@keyframes cow {
  0% {
    bottom: 0;
    width: 100px;
    opacity: 1;
    rotate: 0deg;
  }
  66% {
    bottom: 0;
    width: 100px;
    opacity: 1;
    rotate: 0deg;
  }
  67% {
    bottom: 0;
    width: 100px;
    opacity: 1;
    rotate: 0deg;
  }
  82% {
    bottom: 30%;
    width: 60px;
    opacity: 0;
    rotate: 80deg;
  }
}

@keyframes cowMobile {
  0% {
    bottom: 0;
    width: 80px;
    opacity: 1;
    rotate: 0deg;
  }
  63% {
    bottom: 0;
    width: 80px;
    opacity: 1;
    rotate: 0deg;
  }
  64% {
    bottom: 0;
    width: 80px;
    opacity: 1;
    rotate: 0deg;
  }
  80% {
    bottom: 30%;
    width: 30px;
    opacity: 0;
    rotate: 80deg;
  }
}

$ufoAnimationTime: 4s;

.ufoWrap {
  position: fixed;
  top: -200px;
  left: 50%;
  z-index: 999999;
  width: 240px;
  transform-origin: center;
  //rotate: -15deg;
  //top: 60%;
  //left: 50%;
  //translate: -50%;
  //rotate: 0deg;
  //scale: 1;
  //transform-origin: top left;
  animation: ufo $ufoAnimationTime linear;
  animation-delay: 2s;
  translate: -50%;
  //left: 100%;
  scale: 0.3;

  .ufoAlien {
    position: absolute;
    top: 20px;
    left: 50%;
    width: 90px;
    translate: -50%;
  }

  .ufoBody {
    position: relative;
    z-index: 2;
    width: 240px;
    height: 60px;
    margin: -8px 0 0 0;
    background: #273239;
    border-radius: 100% / 125% 125% 80% 80%;
    aspect-ratio: 3 / 8;

    &:after {
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 100%;
      height: 12px;
      background: #0ebeff;
      border-radius: 10px;
      content: '';
    }

    &:before {
      position: absolute;
      top: 63%;
      display: block;
      z-index: 2;
      width: 8px;
      height: 8px;
      box-shadow: #FFDF07 5px -6px,
      #FFDF07 25px -6px, #FFDF07 45px -6px,
      #FFDF07 65px -6px,
      #FFDF07 85px -6px,
      #FFDF07 105px -6px,
      #FFDF07 125px -6px,
      #FFDF07 145px -6px,
      #FFDF07 165px -6px,
      #FFDF07 185px -6px,
      #FFDF07 205px -6px,
      #FFDF07 225px -6px;
      border-radius: 8px;
      animation: ufoLight 0.5s ease infinite alternate;
      content: '';
    }

    .ufoBodyFoot {
      position: absolute;
      top: 90%;
      width: 4px;
      height: 80px;
      background: #273239;

      &:after {
        position: absolute;
        bottom: 0;
        display: block;
        width: 20px;
        height: 6px;
        margin: 0 0 0 -8px;
        background: #273239;
        border-radius: 100% / 125% 125% 80% 80%;
        content: '';
        aspect-ratio: 3 / 8;
      }

      &:nth-of-type(1) {
        left: 20%;
        rotate: 10deg;
      }

      &:nth-of-type(2) {
        left: 80%;
        rotate: -10deg;
      }
    }
  }

  .ufoGlass {
    position: relative;
    z-index: 1;
    width: 140px;
    height: 100px;
    margin: 0 auto;
    box-shadow: inset 0 4px 8px #0ebeff;
    background: rgba(14, 190, 255, 0.3);
    border-top-right-radius: 125px;
    border-top-left-radius: 125px;

    &:after {
      position: absolute;
      top: 10px;
      left: 80px;
      display: block;
      width: 15px;
      height: 15px;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 100%;
      content: "";
      aspect-ratio: 3 / 8;
    }
  }

  .light {
    position: absolute;
    top: 90%;
    left: 50%;
    width: 0;
    height: 250%;
    background-image: linear-gradient(rgba(225, 225, 0, 0.8), transparent);
    opacity: 0;
    animation: light $ufoAnimationTime linear;
    animation-delay: 2s;
    translate: -50%;
    clip-path: polygon(25% 0, 0 100%, 95% 100%, 75% 0);
  }

  &.hide {
    animation: none;

    .light {
      animation: none;
    }
  }
}

.cow {
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 1;
  width: 100px;
  opacity: 0;
  animation: cow $ufoAnimationTime linear;
  animation-delay: 2s;
  translate: -50%;

  &.hide {
    animation: none;
  }
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .ufoWrap {
    top: -200px;
    animation: ufoMobile $ufoAnimationTime linear;
    animation-delay: 2s;

    .light {
      animation: light $ufoAnimationTime linear;
      animation-delay: 2s;
      height: 400%;
    }

    &.hide {
      animation: none;

      .light {
        animation: none;
      }
    }
  }

  .cow {
    width: 80px;
    animation: cowMobile $ufoAnimationTime linear;
    animation-delay: 2s;

    &.hide {
      animation: none;
    }
  }
}
