@keyframes dayNightAnimation {
  0%, 20% {
    transform: rotate(0)
  }
  40%, 60% {
    transform: rotate(.5turn)
  }
  80%, 100% {
    transform: rotate(1turn)
  }
}

.mainLoader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: var(--loader-background);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    width: 160px;
    height: 80px;
    border-radius: 100px 100px 0 0;
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      inset: 0 0 -100%;
      background: radial-gradient(farthest-side, #ffd738 80%, #0000) left 70% top 20%/30px 30px no-repeat,
      radial-gradient(farthest-side, #21212c 92%, #0000) left 65% bottom 19%/30px 30px no-repeat,
      radial-gradient(farthest-side, #ecfefe 92%, #0000) left 70% bottom 20%/30px 30px no-repeat,
      linear-gradient(#b8cdec 50%, #21212c 0) no-repeat;
      animation: dayNightAnimation 1s infinite;
    }
  }
}
